import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import Judges from "../components/judges"

const SponsorsPage = () => (
  <Background>
    <Layout>
      <Seo title="Judges & Partners" />
      <Container>
        <PageTitle text="Judges & Partners" />
        <Judges />
        {/* <div className="text-center mb-5 pb-5">
          <PrimaryButton url="https://get.govnewsdirect.com/swl21-want-to-be-a-partner/" text="Want to be a partner?" />
        </div> */}
      </Container>
    </Layout>
  </Background>
)

export default SponsorsPage
